<template>
	<div>
		<el-dialog :visible.sync="shows" width="400px" :show-close="false" append-to-body destroy-on-close
			:close-on-click-modal="false">
			<div class="close cur" @click="close">
				<img src="../assets/img/close.png" alt="">
			</div>
			<div class="topPos cur" @click="changeStatus">
				<img v-if="status == 1" src="../assets/img/loginys.png" alt="">
				<img v-else src="../assets/img/logincode.png" alt="">
			</div>
			<div class="topImg">
				<img src="../assets/img/loginlogo.png" alt="">
			</div>
			<div v-if="status == 1" class="qrcode">
				<img :src="$utils.addurl(img)" alt="">
			</div>
			<div v-if="status == 2" class="topTab">
				<el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal"
					active-text-color="#C3272B" @select="handleSelect">
					<el-menu-item index="1">账号登录</el-menu-item>
					<el-menu-item index="2">短信登录</el-menu-item>
				</el-menu>
				<div v-if="activeIndex == '1'" class="zhLogin">
					<div class="ipt" style="margin-bottom: 1.5rem;">
						<el-input v-model="account" placeholder="请输入账号" />
					</div>
					<div class="ipt">
						<el-input v-model="password" placeholder="请输入密码" v-show="passShow" />
						<el-input v-model="password" placeholder="请输入密码" type="password" v-show="!passShow" />
						<img src="../assets/img/showpass.png" alt="" class="passimg cur" @click="changePass"
							v-show="passShow" />
						<img src="../assets/img/hidepass.png" alt="" class="passimg cur" @click="changePass"
							v-show="!passShow" />
					</div>
					<div class="zmSec flexbetween">
						<div class="isRemerberZh flexleft">
							<el-checkbox v-model="checked">
								记住账号
							</el-checkbox>
						</div>
						<div class="forgetPass cur" @click="$router.push('forgetPassWord')">
							忘记密码?
						</div>
					</div>
				</div>
				<div v-if="activeIndex == '2'" class="codeLogin">
					<div class="ipt" style="margin-bottom: 1.5rem;">
						<el-input v-model="phone" placeholder="已认证手机号" />
					</div>
					<div class="ipt flexleft">
						<el-input v-model="code" placeholder="验证码" />
						<div class="senCode cur" @click="sendPhoneCode">
							{{ txt }}
						</div>
					</div>
				</div>
				<div class="btns">
					<div class="login cur" @click="login">
						登录
					</div>
					<div class="register cur" @click="toRigester">
						会员注册
					</div>
				</div>
			</div>
			<div v-if="status == 1" class="txt under cur" @click="changeStatus">
				其他方式登录
			</div>
			<div class="txt">
				登录即表示已阅读并同意 <span @click="toxy(1)" class="cur">《用户协议》</span> 和 <span class="cur"
					@click="toxy(2)">《隐私政策》</span>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		name: 'Qrcode',
		data() {
			return {
				passShow: false,
				txt: '发送验证码',
				checked: false,
				phone: '',
				code: '',
				password: '',
				account: '',
				shows: false,
				status: 1,
				activeIndex: '1',
				img: '',
				phoneLoading: false,
				timers: '',
				timer: '',
				sign: '',
				userinfo: '',
				moneyInfo: '',
			}
		},
		// mounted() {
		// 	this.getNum();
		// },
		beforeDestroy() {
			clearInterval(this.timer);
		},
		methods: {
			changePass() {
				this.passShow = !this.passShow;
			},
			show() {
				let that = this;
				that.shows = true;
				that.$api.yms.web_login().then((res) => {
					if (res.code == 1) {
						that.img = res.data.path;
						that.sign = res.data.sign;
						that.shows = true;
						that.timer = setInterval(() => {
							that.getStatus(); //获取响应
						}, 1000)
					}
				});
			},
			toxy(num) {
				this.shows = false;
				window.open(`ysMent?status=${num}`)
				// this.$router.push({
				// 	path: 'ysMent',
				// 	query: {
				// 		status: num
				// 	}
				// });
			},
			getStatus() {
				let that = this;
				that.$api.yms.checkStatus({
					sign: that.sign,
				}).then(e => {
					if (e.code == 1) {
						if (e.data && e.data == 1) {
							clearInterval(that.timer);
							that.$message({
								message: '登录成功',
								offset: 150,
								type: 'success'
							});
							localStorage.setItem('userId', e.user_id);
							that.shows = false;
							that.getUserInfo();
						}
					}
				})
			},
			getUserInfo() {
				if (!localStorage.getItem('userId')) return;
				let that = this;
				that.$api.yms.user_detail({
					user_id: localStorage.getItem('userId'),
				}).then(e => {
					that.$store.commit('setUserInfo', e.data);
					localStorage.setItem('userinfo', JSON.stringify(e.data));
					if (e.data.qiyeauthentication && e.data.qiyeauthentication.enterprise_id) {
						localStorage.setItem('qyId', e.data.qiyeauthentication.enterprise_id);
						that.getNum();
					}
					if (e.data.username || e.data.qiyeauthentication != null) {
						that.userinfo = e.data;
						if (that.$route.fullPath != '/index') {
							that.$router.push('index');
						}
					} else {
						that.$router.push('bindInfo');
					}
				})
			},
			sendPhoneCode() {
				let that = this;
				if (that.phone == '') {
					that.$message.error('请输入手机号')
					return
				} else if (!that.$utils.mobile(that.phone)) {
					that.$message.error('手机号输入有误')
					return
				}
				that.$api.yms.dx_send({
					type: 2,
					phone: that.phone,
				}).then((res) => {
					if (res.code == 1) {
						that.$message({
							message: '发送成功',
							offset: 150,
							type: 'success'
						});
						let num = 59;
						if (that.phoneLoading) return
						that.phoneLoading = true;
						that.timers = setInterval(() => {
							if (num <= 1) {
								clearInterval(that.timers)
								that.txt = '发送验证码';
								that.phoneLoading = false;
								return
							}
							num--;
							that.txt = num + 's后重新获取';
						}, 1000)
					} else {
						that.$message.error(res.msg);
					}
				});
			},
			login() {
				let that = this;
				if (that.activeIndex == 1) {
					if (that.account == '') {
						that.$message.error('请输入账号')
						return
					} else if (that.password == '') {
						that.$message.error('请输入密码')
						return
					}
				} else {
					if (that.phone == '') {
						that.$message.error('请输入手机号')
						return
					} else if (!that.$utils.mobile(that.phone)) {
						that.$message.error('手机号输入有误')
						return
					} else if (that.code == '') {
						that.$message.error('请输入验证码')
						return
					}
				}
				that.$api.yms.login({
					type: that.activeIndex,
					account: that.activeIndex == 1 ? that.account : that.phone,
					password: that.activeIndex == 1 ? that.password : '',
					code: that.activeIndex == 2 ? that.code : '',
				}).then((res) => {
					if (res.code == 1) {
						that.$message({
							message: '登录成功',
							offset: 150,
							type: 'success'
						});
						clearInterval(that.timer);
						localStorage.setItem('userId', res.data.id);
						that.$store.commit('setUserInfo', res.data);
						localStorage.setItem('userinfo', JSON.stringify(res.data));
						if (res.data.notice_time) {
							localStorage.setItem('isNotice', res.data.notice_time);
						}
						if (res.data.qiyeauthentication && res.data.qiyeauthentication.enterprise_id) {
							localStorage.setItem('qyId', res.data.qiyeauthentication.enterprise_id);
							that.getNum();
						}
						that.getUserInfo();
						that.shows = false;
					} else {
						that.$message.error(res.msg)
					}
				});
			},
			getNum() {
				this.$api.yms.list_count({
					enterprise_id: localStorage.getItem('qyId'),
				}).then((e) => {
					this.moneyInfo = e.data;
					if (e.data.brand_num || e.data.copyright_num || e.data.softright_num || e.data.patent_num) {

						if (!localStorage.getItem('isNotice') || (localStorage.getItem('isNotice') && localStorage.getItem('isNotice') < parseInt(new Date().getTime()) / 1000)) {
							this.open();
						}
					}
					// let totalNum = e.data.brand_num + e.data.copyright_num + e.data.softright_num + e.data.patent_num;
					// if(totalNum){
					// 	this.$notify({
					// 		title: '提示',
					// 		message: '您有' + totalNum +'项知识产权进入预警阶段，请前往个人中心查看'
					// 	});
					// }
				});
			},
			open() {
				let txt = '您的企业有以下续费预警信息：其中'
				if (this.moneyInfo.zzgl) {
					txt = txt + this.moneyInfo.zzgl + '项资质,';
				}
				if (this.moneyInfo.patent_num) {
					txt = txt + this.moneyInfo.patent_num + '项专利,';
				}
				if (this.moneyInfo.copyright_num) {
					txt = txt + this.moneyInfo.copyright_num + '项作品,';
				}
				if (this.moneyInfo.softright_num) {
					txt = txt + this.moneyInfo.softright_num + '项软著,';
				}
				if (this.moneyInfo.brand_num) {
					txt = txt + this.moneyInfo.brand_num + '项商标,';
				}
				txt = txt + '请尽快缴费';
				this.$confirm(txt, '续费预警', {
					confirmButtonText: '我知道了',
					cancelButtonText: '今日不再提醒',
					// type: 'warning',
					dangerouslyUseHTMLString: true,
					showClose:false,
					closeOnClickModal:false,
					closeOnPressEscape:false,
				}).then(() => {

				}).catch(() => {
					this.cancelNotice();
				});
			},
			cancelNotice() {
				this.$api.yms.edit_notice_time({
					user_id: localStorage.getItem('userId'),
				}).then((e) => {

				});
			},
			toRigester() {
				window.open(`register`)
				// this.$router.push({
				// 	path: '/register'
				// })
			},
			changeStatus() {
				let that = this;
				clearInterval(that.timer);
				if (that.status == 1) {
					that.status = 2;
				} else {
					that.status = 1;
					that.timer = setInterval(() => {
						that.getStatus(); //获取响应
					}, 1000)
				}
			},
			close() {
				this.shows = false;
				clearInterval(this.timer);
				this.$bus.$emit('change', false);
			},
			handleSelect(key, keyPath) {
				this.activeIndex = key;
			},
		},
	}
</script>


<style lang="scss" scoped>
	.passimg {
		width: 20px;
		height: 20px;
		position: absolute;
		top: 8px;
		right: 14px;

		img {
			width: 100%;
			height: 100%;
		}
	}

	::v-deep .el-input__inner {
		background-color: #F2F4F4;
		border: 1px solid #DCDCDC;
		color: #919293;
		width: 100% !important;
	}

	::v-deep .el-dialog__wrapper {
		z-index: 2006 !important;
	}

	.codeLogin {
		margin: 1.5rem 2rem;

		.senCode {
			width: 12rem;
			text-align: center;
			color: #C3272B;
			border: 1px solid #C3272B;
			border-radius: 4px;
			padding: 0.55rem 0;
			margin-left: 8px;
		}
	}

	.btns {
		margin: 1rem 2rem;

		.login {
			background-color: #C3272B;
			font-size: 1rem;
			padding: 0.5rem 0;
			text-align: center;
			color: #fff;
		}

		.register {
			margin-top: 1.5rem;
			color: #C3272B;
			text-align: center;
			font-size: 1rem;
			text-decoration: underline;
		}
	}

	::v-deep .el-checkbox__label {
		color: #000 !important;
		padding-left: 0.4rem;
		font-size: 0.8rem;
	}

	.zhLogin {
		margin: 1.5rem auto;

		.zmSec {
			margin: 0.6rem 2rem;

			.forgetPass {
				color: #C3272B;
				font-size: 0.8rem;
			}
		}

		.ipt {
			margin: 0 2rem;
			position: relative;

			input {
				width: 100%;
				padding: 0.5rem 0;
				border: 1px solid #DCDCDC;
				color: #919293;
				outline: none;
				text-indent: 1rem;
				background-color: #F2F4F4;
			}
		}
	}

	.el-menu-demo {
		display: flex;
		justify-content: space-around;
		margin: 0 2rem;
	}

	::v-deep .el-dialog__header {
		display: none;
	}

	::v-deep .el-dialog {
		border-radius: 0.8rem;
	}

	.close {
		width: 2rem;
		height: 2rem;
		position: absolute;
		top: 0rem;
		right: -3rem;

		img {
			width: 100%;
			height: 100%;
		}
	}

	.topPos {
		position: absolute;
		top: 0;
		right: 0;
		width: 4rem;
		height: 4rem;

		img {
			width: 100%;
			height: 100%;
		}
	}

	.txt {
		color: #9E9E9E;
		text-align: center;
		margin: 1rem 0;
	}

	.under {
		text-decoration: underline;
	}

	.qrcode {
		width: 8rem;
		height: 8rem;
		margin: 2rem auto;

		img {
			width: 100%;
			height: 100%;
		}
	}

	.topImg {
		width: 14rem;
		height: 4rem;
		margin: auto;

		img {
			width: 100%;
			height: 100%;
		}
	}
</style>