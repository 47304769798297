<template>
	<div id="app">
		<router-view />
	</div>
</template>

<script>
	export default {
		data() {
			return {};
		},
		created() {
			// if (this.$route.path !== '/login') {
			// 	if(!window.localStorage.getItem('userId')){
			// 		this.$message.error('登录失效，请重新登录')
			// 		this.$router.push({
			// 			path: 'login'
			// 		})
			// 	}
			// }
		},
		methods: {},
	};
</script>

<style lang="scss">
	// ::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
	// 	background-color: #C3272B;
	// }

	// ::v-deep .el-pagination.is-background .el-pager li:not(.disabled):hover {
	// 	color: #C3272B;
	// }

	// ::v-deep .el-pager li.active {
	// 	color: #C3272B;
	// }

	.dlshows {
		height: 12.5rem;
		overflow: hidden;
		position: relative;

		.dlopa {
			width: 100%;
			position: absolute;
			bottom: 0;
			left: 0;
			background: #fff;
		}
	}

	input {
		outline: none;
	}

	.dlshow {
		font-size: 1.25rem;
		color: #0070c0;
		text-align: center;
		line-height: 5rem;
	}

	.fullPage {
		background-color: #F4F6F8;
		min-height: 100vh;
	}

	.hidden {
		// display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 1;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.pageY {
		margin-top: 1rem;
	}

	.cur {
		cursor: pointer;
	}

	.flextop {
		display: flex;
		align-items: flex-start;
		justify-content: center;
	}

	.flextops {
		display: flex;
		align-items: flex-start;
	}

	.flexcenter {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.flexcolumn {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
	}

	.flexbottom {
		display: flex;
		align-items: flex-end;
		justify-content: center;
	}

	.flexbetween {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.flexleft {
		display: flex;
		align-items: center;
	}

	.flexright {
		display: flex;
		align-items: center;
		justify-content: flex-end;
	}

	.flexaround {
		display: flex;
		justify-content: space-around;
		align-items: center;
	}

	.flexstart {
		display: flex;
		justify-content: flex-start;
		align-items: flex-start;
	}

	.flex_wrap {
		flex-wrap: wrap;
	}

	.table1 {
		.el-table__header {
			.el-table__cell {
				background-color: #eef2fa;
				border: 0;
			}
		}

		.el-table__row:hover {
			cursor: pointer;
		}
	}

	.xuanzhong {
		.el-tabs__item.is-active {
			border-top: #C3272B;
		}

	}

	.element {
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 1;
		-webkit-box-orient: vertical;
	}

	.element2 {
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
	}

	.element3 {
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 3;
		-webkit-box-orient: vertical;
	}
</style>